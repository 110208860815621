<template>
  <v-card :loading="loadingTradeReview" fill-height flat class="wl-page-module">
    <v-card-title>
      Trade Review
      <v-spacer />
      <v-menu offset-y bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            v-bind="attrs"
            v-on="on"
            icon
            :disabled="loadingTradeReview"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-if="(selectedTrades || []).length">
            <v-list-item-title>
              <v-btn
                outlined
                color="bobGreen"
                width="100%"
                @click="() => releaseTrades(selectedTrades)"
              >
                <v-icon>mdi-list-status</v-icon>
                Release Selected
              </v-btn>
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if="(tradeReviewItems || []).length">
            <v-list-item-title>
              <v-btn
                outlined
                color="bobGreen"
                width="100%"
                @click="() => releaseTrades(tradeReviewItems)"
              >
                <v-icon>mdi-playlist-check</v-icon>
                Release All
              </v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-title>
    <v-card-text v-if="loadingTradeReview">
      <v-skeleton-loader type="table-tbody" />
    </v-card-text>
    <v-card-text v-if="!loadingTradeReview">
      <v-text-field
        label="Search Trades for Review"
        v-model="searchTradeReviewValue"
        outlined
        color="bobGreen"
        @keydown="initSearch"
      />
      <v-data-table
        :headers="headers"
        :items="tradeReviewItems"
        :search="searchTradeReview"
        :page="page"
        item-key="id"
        :expanded="expanded"
        @pagination="($event) => (page = $event.page)"
        @click:row="toggleRowExpansion"
      >
        <template v-slot:item.actions="{ item }">
          <v-menu offset-y bottom left v-if="item">
            <template v-slot:activator="{ on, attrs }">
              <v-btn fab v-bind="attrs" v-on="on" icon>
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-title>
                  <v-btn outlined color="bobGreen" @click="() => true">
                    <v-icon>mdi-check-cicle-outline</v-icon>
                    Release Trade
                  </v-btn>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>
                  <v-btn
                    outlined
                    color="bobGreen"
                    @click="() => toggleRowExpansion(item)"
                  >
                    <v-icon>mdi-check-cicle-outline</v-icon>
                    Modify
                  </v-btn>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:item.select="{ item }">
          <v-checkbox
            v-if="item"
            @change="($event) => tradeSelected($event, item)"
          />
        </template>
        <template v-slot:expanded-item="{ item }">
          <td :colspan="headers.length">
            <v-card flat class="expanded-trade-review">
              <v-card-text>
                <AdvisorTradeReviewDetail
                  :trade-review-id="item.tradeReviewId"
                />
              </v-card-text>
            </v-card>
          </td>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { AdvisorTradeReviewColumns } from './lib/columns'
import AdvisorTradeReviewDetail from './AdvisorTradeReviewDetail'
import { v4 } from 'uuid'

export default {
  components: {
    AdvisorTradeReviewDetail,
  },
  computed: {
    ...mapGetters({
      loadingTradeReview: 'tradeReview/getLoadingTradeReview',
      tradeReview: 'tradeReview/getTradeReview',
    }),
    tradeReviewItems() {
      return (this.tradeReview || []).map((t) => ({
        id: v4(),
        ...t,
        select: true,
        actions: true,
      }))
    },
  },
  data() {
    return {
      headers: AdvisorTradeReviewColumns,
      searchTradeReviewValue: undefined,
      searchTradeReview: undefined,
      page: 1,
      selectedTrades: [],
      expanded: [],
    }
  },
  mounted() {
    this.getTradeReview()
  },
  methods: {
    ...mapActions({
      getTradeReview: 'tradeReview/getTradeReview',
      confirmTrades: 'tradeReview/confirmTrades',
    }),
    initSearch($event) {
      this.debounce = setTimeout(() => {
        this.searchTradeReview = this.searchTradeReviewValue
        this.page = 1
      }, 1000)
      if ($event.key === 'Enter') {
        this.searchTradeReview = this.searchTradeReviewValue
        this.page = 1
        clearTimeout(this.debounce)
      }
    },
    releaseTrades(trades) {
      console.log('trades', trades)
      this.confirmTrades(trades)
    },
    tradeSelected(selected, trade) {
      console.log(selected, trade)
      if (selected) {
        if (!this.selectedTrades.includes(trade)) {
          this.selectedTrades.push(trade)
        }
      } else {
        this.selectedTrades = this.selectedTrades.filter((t) => t !== trade)
      }
    },
    toggleRowExpansion(item) {
      if (this.expanded[0] === item) {
        this.expanded = []
        return
      }
      this.expanded = [item]
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
.expanded-trade-review {
  margin-top: 6px;
  margin-bottom: 6px;
}
</style>
