<template>
  <v-card flat>
    <v-card-text>
      <v-flex row fill-height>
        <v-flex xs12 md12 lg12 fill-height>
          <ClientSummary />
        </v-flex>
      </v-flex>
      <v-flex row fill-height>
        <v-flex xs12 md12 lg12 fill-height>
          <ClientPositions />
        </v-flex>
      </v-flex>
    </v-card-text>
  </v-card>
</template>

<script>
import ClientPositions from '@/components/ClientPositions'
import ClientSummary from '@/components/ClientSummary'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    ClientPositions,
    ClientSummary,
  },
  metaInfo: {
    title: `Client Detail`,
  },
  computed: {
    ...mapGetters({
      client: 'client/getClient',
      loadingClient: 'client/getLoadingClient',
    }),
  },
  watch: {
    async '$route.params.id'() {
      await this.getClientById(this.$route.params.id)
    },
  },
  async mounted() {
    await this.getClientById(this.$route.params.id)
  },
  methods: {
    ...mapActions({
      getClientById: 'client/getClientById',
    }),
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
</style>
