import axios from 'axios'

export const namespaced = true

export const state = {
  modelClients: [],
  client: undefined,
  loadingModelClients: false,
  loadingClient: false,
}

export const getters = {
  getModelClients() {
    return state.modelClients
  },
  getLoadingModelClients() {
    return state.loadingModelClients
  },
  getClient() {
    return state.client
  },
  getLoadingClient() {
    return state.loadingClient
  },
}

export const mutations = {
  setModelClients(state, modelClients) {
    state.modelClients = modelClients
  },
  setLoadingModelClients(state, value) {
    state.loadingModelClients = value
  },
  setClient(state, client) {
    state.client = client
  },
  setLoadingClient(state, value) {
    state.loadingClient = value
  },
}

export const actions = {
  async getModelClients({ commit }, modelId) {
    commit('setLoadingModelClients', true)
    const modelClientsData = await axios
      .get(`/v0/clients-by-model/${modelId}`)
      .catch((e) => e.response)
    if (modelClientsData.data) {
      commit('setModelClients', modelClientsData.data)
    }
    commit('setLoadingModelClients', false)
  },
  async getClientById({ commit }, clientId) {
    commit('setLoadingClient', true)
    const clientData = await axios
      .get(`/v0/client-by-id/${clientId}`)
      .catch((e) => e.response)
    if (clientData.data) {
      commit('setClient', clientData.data)
    }
    commit('setLoadingClient', false) //
  },
}
