<template>
  <v-card :loading="loadingClient" fill-height flat class="wl-page-module">
    <v-card-text v-if="loadingClient && !client">
      <v-skeleton-loader type="text@3" />
    </v-card-text>
    <v-card-title v-if="!loadingClient && client">
      {{ formattedName }}
    </v-card-title>
    <v-card-text v-if="loadingClient && !client">
      <v-skeleton-loader type="table-tbody" />
    </v-card-text>
    <v-card-text v-if="!loadingClient && client">
      <v-flex row v-if="(client || {}).notInFund">
        <v-spacer />
        <v-flex xs4>
          <h2>
            <v-icon x-large class="wl-top-margin-minus-14">
              mdi-account-search-outline
            </v-icon>
            <span>
              Looks like this client has no position information
            </span>
          </h2>
        </v-flex>
        <v-spacer />
      </v-flex>
      <v-flex row v-else>
        <v-flex xs3>
          <v-card flat>
            <v-card-title>
              <small>Cash</small>
            </v-card-title>
            <v-card-text>
              <h2>{{ currencyFilter((client || {}).tdaCash) }}</h2>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs3>
          <v-card flat>
            <v-card-title>
              <small>Total Account Value</small>
            </v-card-title>
            <v-card-text>
              <h2>
                {{ currencyFilter((client || {}).actualTotalAccountValue) }}
              </h2>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs3>
          <v-card flat>
            <v-card-title>
              <small>Reserves</small>
            </v-card-title>
            <v-card-text>
              <h2>{{ currencyFilter((client || {}).reserves) }}</h2>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs3>
          <v-card flat>
            <v-card-title>
              <small>Holdings Value</small>
            </v-card-title>
            <v-card-text>
              <h2>{{ currencyFilter((client || {}).fundSecuritiesValue) }}</h2>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-flex>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { formatPercent } from '@/utils/number'
import currencyFilter from '@/utils/currency'

export default {
  data() {
    return {
      formatPercent,
      currencyFilter,
    }
  },
  computed: {
    ...mapGetters({
      client: 'client/getClient',
      loadingClient: 'client/getLoadingClient',
    }),
    formattedName() {
      const name = this.client?.name
      const firstName = this.client?.firstName || ''
      const lastName = this.client?.lastName || ''
      const formattedName = name ? name : `${firstName} ${lastName}`
      return formattedName
    },
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
</style>
