<template>
  <v-card :loading="loadingModel" fill-height flat class="page-module">
    <v-skeleton-loader v-show="loadingModel" type="header" />
    <v-card-title>
      Symbols
      <v-spacer />

      <v-progress-circular
        width="4"
        :color="
          modelWeight > 100
            ? 'error'
            : modelWeight < 100
            ? 'warning'
            : 'bobGreen'
        "
        :value="modelWeight"
        :rotate="360"
        :size="65"
        small
      >
        {{ modelWeight }}%
      </v-progress-circular>
      <v-spacer />
      <v-menu offset-y bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn fab v-bind="attrs" v-on="on" icon :disabled="loadingModel">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-if="modelChanged">
            <v-list-item-title>
              <v-btn
                outlined
                color="error"
                @click="() => cancelModelChanges(model._id)"
              >
                <v-icon>mdi-plus</v-icon>
                Cancel Changes
              </v-btn>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <v-btn
                outlined
                color="bobGreen"
                @click="showAddSymbolDialog = true"
              >
                <v-icon>mdi-plus</v-icon>
                Add Symbol
              </v-btn>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <v-btn
                :disabled="!validModelWeight || !modelChanged"
                outlined
                color="bobGreen"
                @click="() => previewModelWeightUpdates(model)"
              >
                <v-icon>mdi-content-save-settings-outline</v-icon>
                Preview Changes
              </v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-title>
    <v-skeleton-loader v-show="loadingModel" type="table" />
    <v-card-text v-if="!loadingModel">
      <v-data-table
        :headers="symbolHeaders"
        :items="symbols"
        :calculate-widths="true"
      >
        <template v-slot:item.symbol="{ item }">
          <h3>{{ item.symbol }}</h3>
        </template>
        <template v-slot:item.allocation="{ item }" class="allocation-card">
          <div class="allocation-card">
            <v-hover :close-delay="10" :open-delay="20">
              <template v-slot:default="{ hover }">
                <v-card flat color="#eeeeee">
                  <v-card-text>
                    <h2>{{ formatPercent(item.allocation || 0) }} %</h2>
                    <v-fade-transition>
                      <v-overlay absolute v-if="hover">
                        <v-btn
                          outlined
                          color="white"
                          small
                          @click="() => toggleSymbolAllocationDialog(item)"
                        >
                          Change Allocation
                        </v-btn>
                      </v-overlay>
                    </v-fade-transition>
                  </v-card-text>
                </v-card>
              </template>
            </v-hover>
          </div>
        </template>
      </v-data-table>
    </v-card-text>
    <v-dialog v-model="showAddSymbolDialog" width="40%">
      <v-card>
        <v-card-title class="wl-modal-header">Add Symbol</v-card-title>
        <v-card-text>
          <br />
          <v-autocomplete
            v-model="newSymbol"
            :items="symbolData"
            outlined
            placeholder="Search symbols ..."
            color="bobGreen"
          />
          <br />
        </v-card-text>
        <v-card-actions class="add-symbol-actions">
          <v-spacer></v-spacer>
          <v-btn outlined @click="showAddSymbolDialog = false">
            Cancel
          </v-btn>
          <v-btn outlined @click="addSymbol" color="bobGreen">
            Add Symbol
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="activeSymbol"
      v-model="showSymbolAllocationDialog"
      width="40%"
    >
      <v-card>
        <v-card-title class="wl-modal-header">
          {{ activeSymbol.symbol }}
        </v-card-title>
        <v-card-text>
          <br />
          <v-flex row>
            <v-spacer />
            <h2>
              Current Allocation:
              {{ formatPercent(activeSymbol.allocation) }} %
            </h2>
            <v-spacer />
          </v-flex>
          <br />
          <v-divider />
          <br />
          <v-text-field
            class="allocation-input"
            autofocus
            v-model="activeSymbol.allocationPercent"
            label="New Allocation"
            type="number"
            step=".01"
            outlined
            color="bobGreen"
            dense
          />
        </v-card-text>
        <v-card-actions class="add-symbol-actions">
          <v-spacer></v-spacer>
          <v-btn outlined @click="showSymbolAllocationDialog = false">
            Cancel
          </v-btn>
          <v-btn outlined @click="updateSymbolAllocation" color="bobGreen">
            Update Symbol
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { formatPercent } from '@/utils/number'
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      formatPercent,
      symbols: [],
      showSymbolAllocationDialog: false,
      showAddSymbolDialog: false,
      activeSymbol: undefined,
      newSymbol: undefined,
      symbolHeaders: [
        {
          text: 'Symbol',
          value: 'symbol',
          width: '30%',
        },
        {
          text: 'Allocation',
          value: 'allocation',
          width: '70%',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      model: 'model/getModel',
      loadingModel: 'model/getLoadingModel',
      symbolData: 'symbol/getSymbols',
      modelWeight: 'model/getModelWeight',
      modelChanged: 'model/getModelChanged',
    }),
    validModelWeight() {
      console.log('this.modelWeight', this.modelWeight)
      return this.modelWeight === 100
    },
  },
  watch: {
    model() {
      this.setSymbols()
    },
  },
  async mounted() {
    this.setSymbols()
  },
  methods: {
    ...mapActions({
      addTickerToModel: 'model/addTickerToModel',
      updateTickerInModel: 'model/updateTickerInModel',
      updateModelWeights: 'model/updateModelWeights',
      previewModelWeightUpdates: 'model/previewModelWeightUpdates',
      cancelModelChanges: 'model/cancelModelChanges',
    }),
    setSymbols() {
      this.symbols = this.model?.symbols || []
    },
    toggleSymbolAllocationDialog(symbolObject) {
      this.showSymbolAllocationDialog = true
      this.activeSymbol = symbolObject
      this.activeSymbol.allocationPercent = formatPercent(
        symbolObject.allocation
      )
    },
    updateSymbolAllocation() {
      this.updateTickerInModel(this.activeSymbol)
      this.activeSymbol = undefined
      this.showSymbolAllocationDialog = false
    },
    addSymbol() {
      this.addTickerToModel({ symbol: this.newSymbol, allocation: 0 })
      this.newSymbol = undefined
      this.showAddSymbolDialog = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.symbol-label {
  margin-left: 20px;
  font-weight: 600;
  font-size: 22px;
}
.page-module {
  border: 1px solid black;
  border-radius: 10px;
  margin: 10px;
}
.allocation-input {
  width: 100%;
}
.v-input::v-deep {
  height: 30px !important;
  width: 100%;
}
.allocation-card {
  width: 100%;
}

.add-symbol-actions {
  margin-right: 16px;
}
</style>
