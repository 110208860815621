<template>
  <v-card flat>
    <v-skeleton-loader v-show="loadingModel" type="header" />
    <v-card-title v-if="!loadingModel">{{ (model || {}).name }}</v-card-title>
    <v-card-text>
      <v-flex row fill-height>
        <v-flex xs12 md6 lg6 fill-height>
          <ModelSymbolsPreview v-if="previewingChanges" />
          <ModelSymbols v-else />
        </v-flex>

        <v-flex xs12 md6 lg6 fill-height>
          <ModelFunds />
        </v-flex>
        <v-flex xs12 md12 lg12 fill-height>
          <ModelClients :model-id="$route.params.id" />
        </v-flex>
      </v-flex>
    </v-card-text>
  </v-card>
</template>

<script>
import ModelSymbols from '@/components/ModelSymbols'
import ModelSymbolsPreview from '@/components/ModelSymbolsPreview'
import ModelClients from '@/components/ModelClients'
import ModelFunds from '@/components/ModelFunds'
import { mapActions, mapGetters } from 'vuex'
export default {
  components: {
    ModelSymbols,
    ModelClients,
    ModelFunds,
    ModelSymbolsPreview,
  },
  data() {
    return {}
  },
  metaInfo: {
    title: `Model Detail`,
  },
  computed: {
    ...mapGetters({
      model: 'model/getModel',
      loadingModel: 'model/getLoadingModel',
      modelChanged: 'model/getModelChanged',
      previewingChanges: 'model/getPreviewingChanges',
    }),
  },
  watch: {
    async '$route.params.id'() {
      if (this.previewingChanges || this.modelChanged) {
        return console.warn('In the middle of changes!!')
      }
      await this.getModel()
    },
    model() {},
  },
  async mounted() {
    await this.getModel()
  },
  methods: {
    ...mapActions({
      getModelDetail: 'model/getModelDetail',
    }),
    async getModel() {
      await this.getModelDetail(this.$route.params.id)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.symbol-label {
  margin-left: 20px;
  font-weight: 600;
  font-size: 22px;
}

.allocation-input {
  width: 100%;
}
.v-input::v-deep {
  height: 30px !important;
  width: 100%;
}
.allocation-card {
  width: 100%;
}

.add-symbol-actions {
  margin-right: 16px;
}
</style>
