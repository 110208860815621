import axios from 'axios'
import Vue from 'vue'
import App from './App.vue'
import Vuemeta from 'vue-meta'
import router from './router'
import vuetify from './plugins/vuetify'
import { store } from './state/store'

axios.defaults.baseURL =
  process.env.BASE_URL || window.location.href.includes('localhost')
    ? 'http://localhost:4070'
    : window.location.host

Vue.config.productionTip = false

Vue.use(Vuemeta)

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount('#app')
