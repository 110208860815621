<template>
  <v-overlay
    tabindex="0"
    ref="result-overlay"
    @keydown.native="hideSearchResults"
    @click.native="() => setShowResultsPanel(false)"
  >
    <v-slide-y-transition>
      <v-card
        :loading="loadingResults"
        shaped
        class="sit-above"
        light
        @click.native="($event) => $event.stopPropagation()"
      >
        <v-flex row color="offWhite">
          <h1 class="results-header">Results</h1>
          <v-spacer />
          <GlobalSearchBar
            :initial-query-string="queryString"
            :show-view-results-icon="false"
          />
          <v-spacer />
          <v-btn
            class="padded-fab"
            x-small
            fab
            outlined
            elevation="0"
            @click="() => setShowResultsPanel(false)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
        <v-card-text v-show="loadingResults">
          <v-skeleton-loader type="heading, table-tbody"></v-skeleton-loader>
        </v-card-text>
        <v-card-text v-if="!loadingResults">
          <v-tabs v-model="tabId">
            <v-tab
              v-for="(resultCategory, resultIdx) of queryResults"
              :key="`result-tab-${resultIdx}`"
            >
              {{ resultCategory.type }} ({{ resultCategory.results.length }})
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tabId">
            <v-tab-item
              v-for="(resultCategory, resultIdx) of queryResults"
              :key="`result-tab-item${resultIdx}`"
            >
              <v-data-table
                dense
                :key="`result-table-${resultIdx}`"
                :headers="columns[resultCategory.type] || []"
                :items="resultCategory.results || []"
                :items-per-page="10"
                :fixed-header="true"
                @click.native="($event) => $event.stopPropagation()"
                @click:row="
                  ($event) => handleRowClick(resultCategory.type, $event)
                "
              ></v-data-table>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-slide-y-transition>
  </v-overlay>
</template>

<script>
import GlobalSearchBar from '@/components/GlobalSearchBar'
import { mapMutations, mapGetters } from 'vuex'
import { GlobalSearchResultColumns } from './lib/columns'
export default {
  components: {
    GlobalSearchBar,
  },
  data() {
    console.log('window.visualViewport.height', window.visualViewport.height)
    return {
      tabId: 0,
      columns: GlobalSearchResultColumns,
      tableHeight: window.visualViewport.height,
    }
  },
  computed: {
    ...mapGetters({
      queryResults: 'search/getQueryResults',
      loadingResults: 'search/getLoadingResults',
      queryString: 'search/getQueryString',
      showResultsPanel: 'search/getShowResultsPanel',
    }),
  },
  watch: {
    showResultsPanel(value) {
      if (value) {
        this.$refs['result-overlay'].$el.focus()
      }
    },
  },
  methods: {
    ...mapMutations({
      setShowResultsPanel: 'search/setShowResultsPanel',
    }),
    hideSearchResults($event) {
      if ($event.key === 'Escape') {
        this.setShowResultsPanel(false)
      }
    },
    async handleRowClick(category, row) {
      const categoryMap = {
        models: `/advisor/models/${row?._id}`,
        clients: `/advisor/clients/${row?._id}`,
      }
      this.setShowResultsPanel(false)
      await this.$router.push(categoryMap[category]).catch((e) => e)
    },
  },
}
</script>

<style lang="scss" scoped>
.sit-above {
  z-index: 100;
}
.v-card::v-deep {
  height: 100vh;
  overflow: scroll;
  position: fixed;
  top: 0;
  width: 60vw;
  right: 0;
}

.padded-fab {
  top: 14px;
}
.results-header {
  margin-left: 30px;
  margin-top: 6px;
}
</style>
